import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { mapDefaultTasq } from '@/utils/tasqs';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import workflowApolloClient from '@/lib/appsync/workflow';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import getListEnabledWells from '@/graphql/workflow/queries/getListEnabledWells.graphql';
import getListEnabledPads from '@/graphql/workflow/queries/getListEnabledPads.graphql';
// import isOnline from 'is-online';
import userPreferenceDB from '@/lib/userPreference';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { mapDefaultProducingTasq } from '@/utils/producingTasqs';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'assetsModule',
  store,
})
class AssetsModule extends VuexModule {
  enabledWells: any = [];

  enabledPads: any = [];

  producingWells: TasqJob[] = []

  activeTasq: TasqJob|null|undefined = null;

  @Mutation
  setEnabledWells(data): void {
    this.enabledWells = data;
  }

  @Mutation
  setEnabledPads(data): void {
    this.enabledPads = data;
  }

  @Mutation
  setProducingWells(data): void {
    this.producingWells = data;
  }

  @Mutation
  setActiveProducingTasq(wellName: any): void {
    this.activeTasq = this.producingWells.find((i) => i.wellName === wellName);
  }

  @Action
  async getEnabledPads(): Promise<void> {
    let items: any = [];

    const {
      data: {
		  list_enabled_pads: {
          enabled_pads: pads,
        },
      },
    } = await workflowApolloClient.query({
      query: getListEnabledPads,
      variables: {
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
      },
    });
	  items = pads;

    items = items.map((i) => {
      const parsed = JSON.parse(i);
      return {
        wellName: parsed.NodeID,
        text: parsed.NodeID,
        value: parsed.NodeID,
        id: parsed.NodeID,
        engineerType: 'Producing',
        predictionType: 'Producing',
        assignee: {},
        route: parsed.Route,
        routeId: parsed.RouteID,
        level: parsed.Level,
        wells: parsed.Wells,
      };
    });

    const producingWells = jsonParse(JSON.stringify(items)).map(
      (t: TasqJob) => mapDefaultTasq(t),
	  );

    this.setProducingWells(producingWells);

    items.sort((a, b) => a.wellName.localeCompare(b.wellName));

    // Remove duplicate wells
    const added_wells: any[] = [];
    const results: any[] = [];
    for (let b = 0; b < items.length; b++) {
      const containsWell = (added_wells.indexOf(items[b].wellName) > -1);
      if (!containsWell) {
        added_wells.push(items[b].wellName);
        results.push(items[b]);
      }
    }
    this.setEnabledPads(results);
  }

  @Action
  // eslint-disable-next-line consistent-return
  async getEnabledWells() {
    try {
      let items: any = [];
      if (this.enabledWells && this.enabledWells.length) {
        return this.enabledWells;
      }
      if (navigator.onLine) {
        const {
          data: {
            list_enabled_wells: {
              enabled_wells: wells,
            },
          },
        } = await workflowApolloClient.query({
          query: getListEnabledWells,
          variables: {
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
          fetchPolicy: 'cache-first',

        });
        items = wells;
        if (tasqsListModule.enablePreloading) {
          await userPreferenceDB.setItem('getEnabledWells', wells);
        }
      } else {
        const result = await userPreferenceDB.getItem('getEnabledWells');
        if (result) {
          items = result;
        }
      }

      items = items.map((i) => {
        const parsed = JSON.parse(i);
        return {
          wellName: parsed.NodeID,
          text: parsed.NodeID,
          value: parsed.NodeID,
          id: parsed.NodeID,
          engineerType: 'Producing',
          predictionType: 'Producing',
          assignee: {},
          route: parsed.Route,
          routeId: parsed.RouteID,
          level: parsed.Level,
          wells: parsed.Wells,
        };
      });

      const producingWells = jsonParse(JSON.stringify(items)).map(
        (t: TasqJob) => mapDefaultProducingTasq(t),
      );

      this.setProducingWells(producingWells);
      await userPreferenceDB.setItem('setProducingWells:main', producingWells);

      items.sort((a, b) => a.wellName.localeCompare(b.wellName));

      // Remove duplicate wells
      const added_wells: any[] = [];
      const results: any[] = [];
      for (let b = 0; b < items.length; b++) {
        const containsWell = (added_wells.indexOf(items[b].wellName) > -1);
        if (!containsWell) {
          added_wells.push(items[b].wellName);
          results.push(items[b]);
        }
      }
      this.setEnabledWells(results);
      await userPreferenceDB.setItem('setEnabledWells:main', results);
    } catch (error: any) {
      const producingWells = await userPreferenceDB.getItem('setProducingWells:main');
      const enabledWells = await userPreferenceDB.getItem('setEnabledWells:main');
      if (enabledWells) {
        console.log(enabledWells);
        this.setEnabledWells(enabledWells);
      }
      if (producingWells) {
        this.setProducingWells(producingWells);
      }
      // console.log(error.message);
    }
  }
}

export default getModule(AssetsModule);
